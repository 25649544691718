
export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "User-Friendly Interface",
    description:
      "Our app is designed with a sleek and intuitive interface, making it easy for users of all experience levels to navigate and enjoy.",
  },
  {
    icon: "tradingItemTwo",
    title: "Real-Time Updates",
    description:
      "Stay ahead of the game with live scores, player statistics, and real-time notifications that keep you informed about every crucial moment.",
  },
  {
    icon: "tradingItemThree",
    title: "Customizable Teams",
    description:
      "Create and manage your dream team with our comprehensive player database, allowing you to pick and choose your favorites based on performance and potential.",
  },
  {
    icon: "tradingItemFour",
    title: "Engaging Contests",
    description:
      "Participate in a variety of contests and tournaments, from daily challenges to season-long competitions, with opportunities to win exciting prizes and rewards.",
  },
  {
    icon: "tradingItemFive",
    title: "Multi-Sport Options",
    description:
      "Expand your horizons with our multi-sport platform, offering fantasy leagues for football, basketball, baseball, and more, all within a single app.",
  },
  {
    icon: "tradingItemSix",
    title: "Personalized Experience",
    description:
      "Tailor your app experience with customizable notifications, favorite teams, and player watchlists, ensuring you never miss out on the action that matters most to you.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "choose1",
    title: "Expert Analysis and Insights",
    description:
      "Receive expert match predictions, player performance breakdowns, and in-depth insights to enhance your fantasy cricket experience.",
  },
  {
    icon: "choose2",
    title: "Unmatched User Experience",
    description:
      "Enjoy seamless navigation, real-time updates, and an intuitive interface for an engaging and informed cricket experience.",
  },
  {
    icon: "choose3",
    title: "Comprehensive Player Statistics",
    description:
      "Access detailed player statistics and performance metrics to make strategic fantasy team choices, boosting your winning chances.",
  },
];


export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Warpwing?",
    content:
      "Warpwing is a fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Warpwing?",
    content:
      "Download the Warpwing app from the App Store or Google Play, create an account, and join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
