import React  from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoMdMailOpen } from "react-icons/io";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
  };

  return (
    <>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-5 col-md-6 col-sm-6 my-md-2 my-sm-1">
                <div className="link-detail">
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid mb-3" />
                  </p>
                  <p>
                    Owned and operated by MECHMOBILE SMARTECHNOLOGY PRIVATE LIMITED.,
                    Warpwing.com is an online fantasy game designed for the fans
                    who like to showcase their cricket knowledge and analytical
                    skills.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 my-md-2 my-sm-1">
                <div className="link-detail">
                  <h6>Contact Info</h6>
                  <p>
                    <a href="mailto:Info@warpwing.net">Info@warpwing.net</a>
                  </p>
                  <div className="d-flex align-items-center gap-2 followus ">
                    <p>
                      <FaFacebook fill="var(--primary-200)" size={24} />
                    </p>
                    <p>
                      <FaTwitter fill="var(--primary-200)" size={24} />
                    </p>
                    <p>
                      <FaLinkedin fill="var(--primary-200)" size={24} />
                    </p>
                    <p>
                      <IoMdMailOpen fill="var(--primary-200)" size={24} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container link-detail footer_links">
          <p
            onClick={() => {
              handleLinkClick("home");
            }}
          >
            Home
          </p>
          <p
            onClick={() => {
              handleLinkClick("usp");
            }}
          >
            Features
          </p>
          <p>
            <p
              onClick={() => {
                handleLinkClick("whychoose");
              }}
            >
              Why Choose Us?
            </p>
          </p>
          <p>
            <NavLink to={"/about-us"}>About us</NavLink>
          </p>
          <p>
            <NavLink to={"/contact-us"}>Contact Us</NavLink>
          </p>
          <p>
            <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
          </p>
          <p>
            <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
          </p>
          <p>
            <NavLink to={"/refund-return"}>Refund & Cancellation</NavLink>
          </p>
        </div>
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 Warpwing</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
